import React from 'react'
import styled from 'styled-components'
import OpenIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import Colors from 'theme/colors'
import Screens from 'theme/screens'

const Container = styled.div`
  display: none;

  @media (max-width: ${Screens.sm}) {
    display: block;
  }
`

const IconWrapper = styled.a`
  color: ${Colors.GRAY_700};
  margin-right: 8px;
`

const MenuIcon = ({ onClick, open, ...props }) => (
  <Container {...props}>
    <IconWrapper
      href="/"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick()
      }}>
      {open ? <CloseIcon /> : <OpenIcon />}
    </IconWrapper>
  </Container>
)

export default MenuIcon
