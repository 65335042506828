import texts from './texts.json'

export const useText = () => {
  const t = key => {
    const text = key.split('.').reduce((parent, key) => parent?.[key], texts)
    return text || key
  }

  return { t }
}
