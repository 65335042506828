import { ThemeProvider } from '@maas-components/styles'
import styled from 'styled-components'
import React from 'react'

import Header from './Header'
import Footer from './Footer'

import '@maas-components/fonts'
import './Layout.css'

const Main = styled.main`
  display: flex;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${({ onlyPage }) => !onlyPage ? '128px' : '20px'}; 128px; // 88px from header + 40px margin
`

const Layout = ({ children, onlyPage = false }) => {
  return (
    <ThemeProvider>
      {!onlyPage && (
        <Header />
      )}
      <Main>
        <Content onlyPage={onlyPage}>{children}</Content>
      </Main>
      {!onlyPage && (
        <Footer />
      )}
    </ThemeProvider>
  )
}

export default Layout
