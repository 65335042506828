import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import Logo from 'svgs/mads-pp-secondary-logo.svg'
import { useText } from 'texts'

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${Colors.NEUTRAL_600};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.NEUTRAL_100};
  padding-top: 62px;
  padding-bottom: 62px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${Screens.sm}) {
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 32px;
    align-items: flex-start;
  }
`

const LinksContainer = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 50% 50%;
  grid-auto-flow: dense;
  grid-template-rows: auto auto;
  grid-gap: 8px;

  @media (max-width: ${Screens.sm}) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
`

const linkCss = css`
  text-decoration: none;
  color: ${Colors.NEUTRAL_100};
  white-space: nowrap;

  &:hover,
  &.active,
  &.focus {
    color: ${Colors.NEUTRAL_400};
  }

  @media (max-width: ${Screens.sm}) {
    display: flex;
  }
`

const LinkWrapper = styled.div`
  line-height: 20px;
`

const StyledLink = styled(Link)`
  ${linkCss}
`

const FooterLink = props => (
  <LinkWrapper>
    <StyledLink {...props} />
  </LinkWrapper>
)

const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${Screens.sm}) {
    align-items: flex-start;
    margin-top: 40px;
  }
`

const Copyright = styled.div`
  font-size: 12px;
  line-height: 20px;
  margin-top: 16px;
`

const Footer = () => {
  const { t } = useText()
  return (
    <StyledFooter>
      <Container className="container">
        <LinksContainer>
          <FooterLink to="/">{t('pages.home')}</FooterLink>
          <FooterLink to="/como-funciona">{t('pages.about')}</FooterLink>
          <FooterLink to="/quero-usar">{t('pages.access')}</FooterLink>
          <FooterLink to='https://magaluads.zendesk.com/hc/pt-br' target="_blank">{t('pages.faq')}</FooterLink>
          <FooterLink to="/termos-de-uso">{t('pages.termsOfUse')}</FooterLink>
          <FooterLink to='https://especiais.magazineluiza.com.br/seguranca/' target="_blank">{t('pages.securityAndPrivacy')}</FooterLink>
          <FooterLink to="/politica-privacidade">{t('pages.privacyPolicy')}</FooterLink>
        </LinksContainer>

        <BrandContainer>
          <Link to="/" aria-label={t('footer.logoAriaLabel')}>
            <Logo />
          </Link>
          <Copyright>{t('footer.copyright')}</Copyright>
        </BrandContainer>
      </Container>
    </StyledFooter>
  )
}

export default Footer
