const Colors = {
  NEUTRAL_100: '#FFFFFF',
  NEUTRAL_200: '#F7F7F7',
  NEUTRAL_300: '#ECECEC',
  NEUTRAL_400: '#CECFD1',
  NEUTRAL_500: '#788089',
  NEUTRAL_600: '#424A52',
  NEUTRAL_700: '#282B2F',

  PRIMARY_90: '#2396FE',
  PRIMARY_100: '#0086FF',
  PRIMARY_110: '#0079E6',

  SECONDARY_90: '#FFDA2E',
  SECONDARY_100: '#FCD000',
  SECONDARY_110: '#EBC200',

  ERROR_100: '#E12A45',
  ERROR_110: '#C70E2A',

  SUCCESS_100: '#08A93C',

  // The warning variation is not defined neither in maas-components or in the design system doc
  WARNING_100: '#E68600',
  WARNING_110: '#B05800',

  // This gray is not defined neither in maas-components or in the design system doc
  GRAY_100: '#F5F7F6',
  GRAY_300: '#ECECEC',
  GRAY_700: '#757776',
  GRAY_900: '#3D3E3F'
}

module.exports = Colors
