import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Screens from 'theme/screens'
import RegularLogo from 'svgs/mads-pp-logo.svg'
import { useText } from 'texts'

const StyledLogo = styled(RegularLogo)`
  @media (max-width: ${Screens.md}) {
    height: 40px;
    width: 172px;
  }
`

const Logo = () => {
  const { t } = useText()

  return (
    <Link to="/" aria-label={t('header.logoAriaLabel')}>
      <StyledLogo />
    </Link>
  )
}

export default Logo
